import SharedBackButton from "@/components/shared/shared-back-button";
import LinkSiteLogo from "@/components/ui/link-logo";

const LoginHeader = () => {
  return (
    <header className="border-y-1 sticky top-0 z-40 border-black/5 bg-background shadow-sm shadow-gray-300">
      <nav
        className="mx-auto flex max-w-5xl items-center justify-between px-6 py-4"
        aria-label="Global"
      >
        <div className="flex items-center justify-start gap-4">
          <LinkSiteLogo />
          <SharedBackButton />
        </div>
      </nav>
    </header>
  );
};

export default LoginHeader;
