"use client";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import LoadingButton from "@/components/ui/loading-button";
import { sharedRouteConfig } from "@/config/shared";
import { LoadingDots } from "@/icons";
import { searchSchema } from "@/lib/validation/search";
import { zodResolver } from "@hookform/resolvers/zod";
import { Search, X } from "lucide-react";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";

type SearchFormValues = z.infer<typeof searchSchema>;

type SearchBarProps = {
  visible: boolean;
  setSearchIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const SearchBar = (props: SearchBarProps) => {
  const { visible, setSearchIsVisible } = props;
  const [loading, setIsLoading] = useState<boolean>(false);
  const router = useRouter();

  const defaultValues: Partial<SearchFormValues> = {
    query: "",
  };

  const searchForm = useForm<SearchFormValues>({
    resolver: zodResolver(searchSchema),
    mode: "onChange",
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        searchForm.setFocus("query");
      }, 50);
    }
  }, [visible, searchForm]);

  async function handleSearchAction() {
    if (searchForm.getValues("query") !== "") {
      setIsLoading(true);
      router.push(
        sharedRouteConfig.search + "/" + searchForm.getValues("query"),
      );
      setTimeout(() => {
        setIsLoading(false);
        setSearchIsVisible(!visible);
      }, 2000);
    }
  }

  async function handleSearchRevealAction() {
    setSearchIsVisible(!visible);
  }

  return (
    <>
      <Button
        variant="outline"
        size="icon"
        className="ml-2 mr-2"
        onClick={handleSearchRevealAction}
      >
        {!visible ? (
          <Search className="h-4 w-4 rotate-0 scale-100 transition-all" />
        ) : (
          <X className="h-4 w-4 rotate-0 scale-100 transition-all" />
        )}
        <span className="sr-only">Toggle Search</span>
      </Button>
      <Dialog open={visible} onOpenChange={setSearchIsVisible}>
        <DialogContent className="font-sans sm:max-w-[425px]">
          {visible && (
            <Form {...searchForm}>
              <form onSubmit={searchForm.handleSubmit(handleSearchAction)}>
                <div className="relative flex flex-wrap gap-2 p-4">
                  <FormField
                    control={searchForm.control}
                    name="query"
                    render={({ field }) => (
                      <FormItem className="w-4/6 max-w-md items-center">
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  {!loading ? (
                    <Button type="submit" variant={"outline"} className="w-1/4">
                      Search
                    </Button>
                  ) : (
                    <LoadingButton variant={"outline"} className="w-1/4" />
                  )}
                </div>
              </form>
            </Form>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SearchBar;
