"use client";

import { cn } from "@/lib/utils";
import { Disclosure } from "@headlessui/react";
import { FC } from "react";

interface MainMobileMenuButtonProps {
  open: boolean;
  className?: string | undefined;
}

const MainMobileMenuButton: FC<MainMobileMenuButtonProps> = ({
  open,
  className,
}) => {
  return (
    <>
      <Disclosure.Button
        className={cn(
          "inline-flex h-10 w-10 items-center justify-center rounded-md border border-input bg-background text-xs font-medium ring-offset-background transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 sm:text-sm md:hidden",
          className,
        )}
      >
        <span
          aria-hidden="true"
          className={cn(
            "absolute block h-0.5 w-5 bg-primary transition-transform duration-500 ease-in-out",
            { "rotate-45": open },
            { "-translate-y-1.5": !open },
          )}
        ></span>
        <span
          aria-hidden="true"
          className={cn(
            "absolute block  h-0.5 w-5 bg-primary transition-transform duration-500 ease-in-out",
            { "opacity-0": open },
          )}
        ></span>
        <span
          aria-hidden="true"
          className={cn(
            "absolute block  h-0.5 w-5 bg-primary transition-transform duration-500 ease-in-out",
            { "-rotate-45": open },
            { "translate-y-1.5": !open },
          )}
        ></span>
        {open ? (
          <span className="sr-only">Close Menu</span>
        ) : (
          <span className="sr-only">Open Menu</span>
        )}
      </Disclosure.Button>
    </>
  );
};

export default MainMobileMenuButton;
