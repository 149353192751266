"use client";

import { LoginMenu } from "@/components/login";
import SearchBar from "@/components/main/header/main-search";
import LinkSiteLogo from "@/components/ui/link-logo";
import { ModeToggle } from "@/components/ui/theme-toggle";
import { cn } from "@/lib/utils";
import { Disclosure } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { MainMobileMenuButton, MainMobileNavigationMenu } from "./menu";

const MainDesktopNavigation = () => {
  const [searchVisible, setSearchIsVisible] = useState<boolean>(false);

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <nav
            className={`mx-auto flex h-16 max-w-7xl items-center justify-between bg-background pr-2`}
          >
            {/* Logo */}
            <div className="flex items-center justify-start gap-2 pl-2">
              {/* <SharedBackButton /> */}
              <MainMobileMenuButton open={open} className={"flex"} />
              <LinkSiteLogo
                className={cn(
                  searchVisible ? "hidden sm:flex" : "",
                  "flex items-center justify-start gap-2 pl-2",
                )}
                showName
              />
            </div>

            {/* Login Menu */}
            <div className="flex justify-end gap-2">
              <SearchBar
                visible={searchVisible}
                setSearchIsVisible={setSearchIsVisible}
              />

              <ModeToggle className={"flex"} />
              <LoginMenu className={"flex"} />
            </div>
          </nav>
          {/* Mobile Navigation */}
          <MainMobileNavigationMenu fragment={Fragment} />
        </>
      )}
    </Disclosure>
  );
};

export default MainDesktopNavigation;
