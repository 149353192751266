"use client";

import { LoginMenu } from "@/components/login";
import SearchBar from "@/components/main/header/main-search";
import LinkSiteLogo from "@/components/ui/link-logo";
import { ModeToggle } from "@/components/ui/theme-toggle";
import { Disclosure } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { MainMobileMenuButton, MainMobileNavigationMenu } from "./menu";

const MainMobileNavigation = () => {
  const [searchVisible, setSearchIsVisible] = useState<boolean>(false);

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <nav className="mx-auto flex h-16 max-w-5xl items-center justify-between px-2 md:hidden">
            {/* Mobile Menu Button */}
            <div className="flex flex-1 justify-start gap-4 pl-2">
              <MainMobileMenuButton open={open} />
              <LinkSiteLogo />
            </div>
            {/* Login */}
            <div className="flex justify-end">
              <SearchBar
                visible={searchVisible}
                setSearchIsVisible={setSearchIsVisible}
              />
              <ModeToggle />
              <LoginMenu />
            </div>
          </nav>

          {/* Mobile Navigation */}
          <MainMobileNavigationMenu fragment={Fragment} />
        </>
      )}
    </Disclosure>
  );
};

export default MainMobileNavigation;
