import { Button } from "@/components/ui/button";
import { LoadingDots } from "@/icons";
import { cn } from "@/lib/utils";

type LoadingButtonProps = {
  includeText?: boolean;
  variant?:
    | "default"
    | "destructive"
    | "outline"
    | "secondary"
    | "ghost"
    | "link"
    | null
    | undefined;
  className?: string;
};

const LoadingButton = ({
  includeText = false,
  variant = "default",
  className,
}: LoadingButtonProps) => {
  return (
    <Button
      variant={variant}
      className={cn(
        `rounded-md px-8 py-3 font-semibold`,
        includeText ? "flex gap-2" : "",
        className,
      )}
    >
      <LoadingDots />
      {includeText && <span>Loading</span>}
    </Button>
  );
};

export default LoadingButton;
